<template>
  <ContentBlock>
    <div class="subpage__title heading-3-sb grey-100">
      <template>
        {{ $t('Main.Ui.btStartPageExamples') }}
      </template>
    </div>
    <div class="examples__cards">
      <Card
        v-for="example in examplesList"
        :key="example.id"
        class="examples__card"
        :title="example.name"
        :description="example.description"
        @card-click="handleExampleSwitch(example.id)"
      >
        <template #icon>
          <icon
            :data="getIcon(example)"
            class="size-24 fill-off color-primary-100"
          />
        </template>
      </Card>
    </div>
  </ContentBlock>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Card from '@/components/Examples/Card.vue';
import { iconNameById } from '@/config/startPage';
import { ampli } from '@/plugins/product/ampli';

export default {
  name: 'Examples',
  components: {
    Card
  },
  data() {
    return {
      isOpening: false
    };
  },
  computed: {
    ...mapState({
      examplesList: state => state.manageProjects.examples
    })
  },
  methods: {
    ...mapActions({
      openExample: 'manageProjects/openExample'
    }),
    handleExampleSwitch(id) {
      if (this.isOpening) {
        return;
      }

      this.openExample(id);
      this.isOpening = true;

      ampli.exampleOpened({ projectName: id });
    },
    getIconName({ id }) {
      return iconNameById[id] || 'default';
    },
    getIcon(example) {
      return require(`@icon/examples/${this.getIconName(example)}.svg`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/utils/_mixins.scss';
@import "@/style/utils/_variable.scss";

.examples {
  &__cards {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px;

    @media screen and (max-width: $width-xl) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  .subpage__table-wrapper {
    height: calc(100% - 120px);
  }
}
</style>