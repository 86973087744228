<template>
  <div
    class="card"
    @click="$emit('card-click')"
  >
    <template v-if="$scopedSlots.icon">
      <div class="card__icon">
        <slot name="icon" />
      </div>
    </template>
    <div class="card__content">
      <div>
        <div class="card__title">
          {{ title }}
        </div>
        <div
          v-if="description"
          class="card__description"
        >
          {{ description }}
        </div>
      </div>
      <div class="card__link">
        <span>{{ $t('Web.Examples.View') }}</span>
        <span class="card__arrow">
          <icon
            data="@icons/arrow_right_long.svg"
            class="size-24 fill-off color-primary-80"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseCard',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    tags: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/examples/card.scss";
</style>